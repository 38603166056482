import envsConfig from './envs.config.json';

type Env = {
  APP_URL: string;
  API_BASE: string;
  AUTH_AUTHORITY: string;
  AUTH_CLIENT_ID: string;
  AUTH_SCOPE: string;
  ADYEN_ENVIRONMENT: string;
  ADYEN_CLIENT_KEY: string;
  APPLE_PAY_MERCHANT_NAME: string;
  APPLE_PAY_MERCHANT_ID: string;
  GOOGLE_PAY_ENV: string;
  FRESH_CHAT_TOKEN: string;
  GTM_ID: string;
  GTM_AUTH: string;
  GTM_PREVIEW: string;
  IOVATION_FP_STATIC: string;
  RECAPTCHA_SITEKEY: string;
  SHOW_COOKIE_CONSENT: string;
  COOKIE_CONSENT_KEY: string;
  MENTION_ME_REFER_URL: string;
};

let cachedEnvConfig: Env | null = null;

export const env = {
  get config() {
    if (cachedEnvConfig) {
      return cachedEnvConfig;
    }

    const envConfig = Object.values(envsConfig).find(
      ({ APP_URL }) => APP_URL === window.location.origin
    );

    if (!envConfig) {
      throw new Error('Unable to find a configuration for this environment.');
    }

    cachedEnvConfig = envConfig;

    return envConfig;
  },
};
