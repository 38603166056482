/* eslint-disable */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import configureApi from './helpers/api/configureApi';
import MswToggler from './mocks/MswToggler';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import configureIovation from './helpers/iovation/configureIovation';
import { polyfill as polyfillSmoothScroll } from 'smoothscroll-polyfill';
import { env } from './env';

// Setup GTM
const tagManagerArgs = {
  gtmId: env.config.GTM_ID || '',
  auth: env.config.GTM_AUTH,
  preview: env.config.GTM_PREVIEW,
};

const delayedGTMInit = () => {
  setTimeout(() => {
    TagManager.initialize(tagManagerArgs);
  }, 2000);
}

delayedGTMInit();

// Setup axe accessibility plugin
if (process.env.NODE_ENV !== 'production') {
  import('@axe-core/react')
    .then(axe => {
      axe.default(React, createRoot, 1000);
    })
    .catch(() => {
      console.error('Failed to load @axe-core/react');
    });
}

if (process.env.NODE_ENV === 'development') {
  import('./mocks/mockBrowser').catch(() => {
    console.error('Failed to start MSW');
  });
}

polyfillSmoothScroll();

configureApi();
configureIovation();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 900000, // 15 Mins
      refetchOnWindowFocus: false,
      retry: false, // Disable query retries.
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    {process.env.NODE_ENV === 'development' && <MswToggler />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
