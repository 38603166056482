import moment from 'moment';
import {
  Duration,
  MarketConsentType,
  Price,
  Quote,
  VehicleFuelType,
  VehicleTransmissionType,
  VehicleType,
  WebApiAddress,
  AddOnType,
  AcceptanceCriteriaResponse,
  GetPersonReponse,
  AssetType,
  MarketingPreference,
  ProductName,
  QuotePersonType,
  UnderWriterName,
} from '../../../api';
import { PaymentFields } from '../../../types/PaymentFields';
import Referrer from '../../../types/referrer';
import { assetTypeToVehicleType } from '../../../types/typeMappers';
import mapUserMarketingPreferencesToReferrer from '../marketingPreferenceds/marketingPreferenceUtils';

export enum QuoteJourneyRoutes {
  RegistrationSearch = 'registration-search',
  ManualVehicleEntry = 'manual-vehicle-entry',
  DriverDetails = 'driver-details',
  ContactDetails = 'contact-details',
  LearnerDriver = 'learner-driver',
  CoverLength = 'cover-length',
  Addons = 'addons',
  AcceptanceStatement = 'acceptance',
  Review = 'review',
  Payment = 'pay',
  PaymentProcessing = 'processing',
  PaymentSuccess = 'success',
  PolicyInfo = 'policy-info',
}

export type FormContextData = {
  journeyStartPosition?:
    | QuoteJourneyRoutes.RegistrationSearch
    | QuoteJourneyRoutes.CoverLength;
  journeyStartTime: Date;
  isLearner?: boolean;
  quoteId?: string;
  acceptanceCriteria?: AcceptanceCriteriaResponse[];
  price?: Price;
  product?: ProductName;
  underwriter?: UnderWriterName;
  excess?: number;
  isQuoteExpired?: boolean;
};

const FormContextInitialData: FormContextData = {
  journeyStartPosition: undefined,
  journeyStartTime: new Date(),
  isLearner: undefined,
  quoteId: undefined,
  acceptanceCriteria: undefined,
  price: undefined,
  product: undefined,
  underwriter: undefined,
  excess: undefined,
  isQuoteExpired: undefined,
};

export type VehicleDetailsFormData = {
  vehicleRegistration: string;
  isConvertedToMotorhome: boolean;
  vehicleType?: { id: VehicleType; name: string };
  yearOfManufacture?: { id: string; name: string };
  vehicleMake?: { id: string; name: string };
  fuelType?: { id: VehicleFuelType; name: string };
  gearboxType?: { id: VehicleTransmissionType; name: string };
  vehicleModel?: { id: string; name: string };
  vehicleAbiCode?: string;
};

const VehicleDetailsInitialFormData: VehicleDetailsFormData = {
  vehicleRegistration: '',
  isConvertedToMotorhome: false,
  vehicleType: undefined,
  yearOfManufacture: undefined,
  vehicleMake: undefined,
  fuelType: undefined,
  gearboxType: undefined,
  vehicleModel: undefined,
  vehicleAbiCode: undefined,
};

export type DriverDetailsFormData = {
  firstName: string;
  surname: string;
  dateOfBirth: { day: string; month: string; year: string; parsedDate?: string };
  licenceNumber: string;
  occupation?: { id: string; name: string; message?: string; warningType?: string };
};

export const DriverDetailsInitialFormData: DriverDetailsFormData = {
  firstName: '',
  surname: '',
  dateOfBirth: { day: '', month: '', year: '', parsedDate: undefined },
  licenceNumber: '',
  occupation: undefined,
};

export type ContactDetailsFormData = {
  emailAddress: string;
  mobileNumber: string;
  postcode: string;
  postkey?: { id: string; name: string };
  address: WebApiAddress | undefined;
  marketingPreferences: { id: MarketConsentType; name: string; checked: boolean }[];
};

export const ContactDetailsInitialFormData: ContactDetailsFormData = {
  emailAddress: '',
  mobileNumber: '',
  postcode: '',
  postkey: undefined,
  address: undefined,
  marketingPreferences: [
    { id: MarketConsentType.EMAIL, name: 'Email', checked: false },
    { id: MarketConsentType.SMS, name: 'Text', checked: false },
    { id: MarketConsentType.TELEPHONE, name: 'Telepone', checked: false },
    { id: MarketConsentType.POST, name: 'Post', checked: false },
  ],
};

export type LearnerDriverFormData = {
  driverLicenseTest?: {
    id: 'driver_license_test_yes' | 'driver_license_test_no';
    name: string;
  };
  registeredKeeper?: { id: 'registeredKeeper_no' | 'registeredKeeper_yes'; name: string };
  registeredKeeperFirstName: string;
  registeredKeeperSurname: string;
  registeredKeeperMobileNumber: string;
  registeredKeeperAddressQuestion?: {
    id: 'registeredKeeperAddress_yes' | 'registeredKeeperAddress_no';
    name: string;
  };
  registeredKeeperPostcode: string;
  registeredKeeperPostkey?: { id: string; name: string };
  registeredKeeperAddress: WebApiAddress | undefined;
  registeredKeeperInsured?: {
    id: 'registeredKeeperInsured_yes' | 'registeredKeeperInsured_no';
    name: string;
  };
};

const LearnerDriverInitialFormData: LearnerDriverFormData = {
  driverLicenseTest: undefined,
  registeredKeeper: undefined,
  registeredKeeperFirstName: '',
  registeredKeeperSurname: '',
  registeredKeeperMobileNumber: '',
  registeredKeeperAddressQuestion: undefined,
  registeredKeeperPostcode: '',
  registeredKeeperPostkey: undefined,
  registeredKeeperAddress: undefined,
  registeredKeeperInsured: undefined,
};

export type CoverLengthFormData = {
  coverStartQuestion?: { id: 'ASAP' | 'EXACT'; name: string };
  coverLength?: Duration & { id: string };
  exactStartDate?: { day: string; month: string; year: string; parsedDate?: string };
  exactStartHour?: { id: string; name: string };
  exactStartMinute?: { id: string; name: string };
  asapStartDate?: Date;
};

const CoverLengthInitialFormData: CoverLengthFormData = {
  coverStartQuestion: { id: 'ASAP', name: 'As soon as possible' },
  coverLength: undefined,
  exactStartDate: { day: '', month: '', year: '', parsedDate: undefined },
  exactStartHour: undefined,
  exactStartMinute: undefined,
};

type AddOnsFormData = {
  addOns?: {
    id: AddOnType;
    name?: string;
    description?: string;
    price: number;
    checked: boolean;
  }[];
  addOnTotal?: number;
};

const AddOnsInitialFormData: AddOnsFormData = {
  addOns: undefined,
  addOnTotal: undefined,
};

const PaymentInitialFormData: PaymentFields = {
  encryptedCardNumber: '',
  cardholderName: '',
  encryptedExpiryDate: '',
  encryptedExpiryMonth: '',
  encryptedExpiryYear: '',
  encryptedSecurityCode: '',
  saveCardDetails: false,
};

export type QuoteJourneyFormData = VehicleDetailsFormData &
  DriverDetailsFormData &
  ContactDetailsFormData &
  LearnerDriverFormData &
  CoverLengthFormData &
  AddOnsFormData &
  PaymentFields &
  FormContextData;

export const getCurrentPageFromRoute = (route: string) => {
  if (route.includes(QuoteJourneyRoutes.RegistrationSearch))
    return QuoteJourneyRoutes.RegistrationSearch;
  if (route.includes(QuoteJourneyRoutes.ManualVehicleEntry))
    return QuoteJourneyRoutes.ManualVehicleEntry;
  if (route.includes(QuoteJourneyRoutes.DriverDetails))
    return QuoteJourneyRoutes.DriverDetails;
  if (route.includes(QuoteJourneyRoutes.ContactDetails))
    return QuoteJourneyRoutes.ContactDetails;
  if (route.includes(QuoteJourneyRoutes.LearnerDriver))
    return QuoteJourneyRoutes.LearnerDriver;
  if (route.includes(QuoteJourneyRoutes.CoverLength))
    return QuoteJourneyRoutes.CoverLength;
  if (route.includes(QuoteJourneyRoutes.Addons)) return QuoteJourneyRoutes.Addons;
  if (route.includes(QuoteJourneyRoutes.AcceptanceStatement))
    return QuoteJourneyRoutes.AcceptanceStatement;
  if (route.includes(QuoteJourneyRoutes.Review)) return QuoteJourneyRoutes.Review;
  if (route.includes(QuoteJourneyRoutes.Payment)) return QuoteJourneyRoutes.Payment;
  if (route.includes(QuoteJourneyRoutes.PaymentProcessing))
    return QuoteJourneyRoutes.PaymentProcessing;
  return undefined;
};

export const getQuoteJourneyInitialData = (
  initialValues?: Partial<QuoteJourneyFormData>
): QuoteJourneyFormData => ({
  ...VehicleDetailsInitialFormData,
  ...DriverDetailsInitialFormData,
  ...ContactDetailsInitialFormData,
  ...LearnerDriverInitialFormData,
  ...CoverLengthInitialFormData,
  ...AddOnsInitialFormData,
  ...PaymentInitialFormData,
  ...FormContextInitialData,
  ...initialValues,
});

export const getInitialDataForSignInMidFlow = (
  person: GetPersonReponse,
  stateData: QuoteJourneyFormData
) => {
  const dob = person.dateOfBirth ? new Date(person.dateOfBirth) : undefined;
  return getQuoteJourneyInitialData({
    ...stateData,
    journeyStartTime: new Date(stateData.journeyStartTime),
    firstName: person.firstName || stateData.firstName,
    surname: person.lastName || stateData.surname,
    dateOfBirth: dob
      ? {
          day: dob ? dob.getDate().toString().padStart(2, '0') : '',
          month: dob ? (dob.getMonth() + 1).toString().padStart(2, '0') : '',
          year: dob ? dob.getFullYear().toString() : '',
          parsedDate: dob ? dob.toISOString() : undefined,
        }
      : stateData.dateOfBirth,
    licenceNumber: person.drivingLicenceNumber || stateData.licenceNumber,
    occupation:
      person.occupation?.id && person.occupation?.description
        ? { id: person.occupation?.id, name: person.occupation?.description }
        : stateData.occupation,
    emailAddress: person.email || stateData.emailAddress,
    mobileNumber: person.mainPhoneNumber || stateData.mobileNumber,
    postcode:
      person.address && person.address.postCode
        ? person.address.postCode
        : stateData.postcode,
    postkey:
      person.address?.postCode && person.address.displayName
        ? { id: '', name: person.address?.displayName }
        : stateData.postkey,
    address: person.address ? person.address : stateData.address,
  });
};

export const getInitialDataForSignedInFlow = (
  person: GetPersonReponse,
  marketingPreferences: Array<MarketingPreference> | null | undefined,
  referrer: Referrer,
  initialValues?: Partial<QuoteJourneyFormData>
) => {
  const dob = person.dateOfBirth ? moment.utc(person.dateOfBirth) : undefined;
  const marketingPrefs = marketingPreferences
    ? mapUserMarketingPreferencesToReferrer(
        marketingPreferences,
        referrer.marketingConsent.options.map(pref => pref.id)
      )
    : referrer.marketingConsent.options.map(pref => ({
        id: pref.id,
        name: pref.name,
        checked: false,
      }));

  return getQuoteJourneyInitialData({
    firstName: person.firstName || '',
    surname: person.lastName || '',
    dateOfBirth: {
      day: dob ? dob.get('date').toString().padStart(2, '0') : '',
      month: dob ? (dob.get('month') + 1).toString().padStart(2, '0') : '',
      year: dob ? dob.get('year').toString() : '',
      parsedDate: dob?.toISOString(),
    },
    licenceNumber: person.drivingLicenceNumber || '',
    occupation:
      person.occupation?.id && person.occupation?.description
        ? { id: person.occupation?.id, name: person.occupation?.description }
        : undefined,
    emailAddress: person.email || '',
    mobileNumber: person.mainPhoneNumber || '',
    postcode: person.address && person.address.postCode ? person.address.postCode : '',
    postkey:
      person.address?.postCode && person.address.displayName
        ? { id: '', name: person.address?.displayName }
        : undefined,
    address: person.address ? person.address : undefined,
    marketingPreferences: marketingPrefs,
    ...initialValues,
  });
};

export const getInitialDataForContinueQuote = (
  id: string,
  quote: Quote,
  marketingPreferences: Array<MarketingPreference> | null | undefined,
  referrer: Referrer
) => {
  const vehicle = quote.assets?.[0];
  const policyHolder = quote.persons?.find(
    p => p.quotePersonType === QuotePersonType.POLICY_HOLDER
  );
  const registeredKeeper = quote.persons?.find(
    p => p.quotePersonType === QuotePersonType.ASSET_OWNER
  );
  const startDate = moment.tz(quote.startDate, 'Europe/London');
  const localStartDate = startDate;
  const isInFuture = moment.utc().add(15, 'minutes').isBefore(startDate);

  const personDetails = policyHolder
    ? getInitialDataForSignedInFlow(policyHolder, marketingPreferences, referrer)
    : {};

  const registeredKeeperDetails: Partial<QuoteJourneyFormData> = {
    isLearner:
      quote.product === ProductName.LEARNER_OWN_MOTOR ||
      quote.product === ProductName.LEARNER_TOP_UP_MOTOR,
  };

  if (quote.product === ProductName.LEARNER_OWN_MOTOR) {
    registeredKeeperDetails.registeredKeeper = {
      id: 'registeredKeeper_yes',
      name: 'Yes',
    };
  }

  if (
    quote.product === ProductName.LEARNER_TOP_UP_MOTOR &&
    registeredKeeper &&
    policyHolder
  ) {
    registeredKeeperDetails.registeredKeeper = { id: 'registeredKeeper_no', name: 'No' };
    registeredKeeperDetails.registeredKeeperFirstName = registeredKeeper.firstName || '';
    registeredKeeperDetails.registeredKeeperSurname = registeredKeeper.lastName || '';
    registeredKeeperDetails.registeredKeeperMobileNumber =
      registeredKeeper.mainPhoneNumber || '';
    registeredKeeperDetails.registeredKeeperInsured = {
      id: 'registeredKeeperInsured_yes',
      name: 'Yes',
    };

    if (
      registeredKeeper.address?.postCode?.toLowerCase().trim().replace(' ', '') ===
      policyHolder?.address?.postCode?.toLowerCase().trim().replace(' ', '')
    ) {
      registeredKeeperDetails.registeredKeeperAddressQuestion = {
        id: 'registeredKeeperAddress_yes',
        name: 'Yes',
      };
      registeredKeeperDetails.registeredKeeperPostcode =
        policyHolder.address?.postCode || '';
      registeredKeeperDetails.registeredKeeperPostkey =
        policyHolder.address?.postCode && policyHolder.address.displayName
          ? { id: '', name: policyHolder.address?.displayName }
          : undefined;
      registeredKeeperDetails.registeredKeeperAddress = policyHolder.address
        ? policyHolder.address
        : undefined;
    } else {
      registeredKeeperDetails.registeredKeeperAddressQuestion = {
        id: 'registeredKeeperAddress_no',
        name: 'No',
      };
      registeredKeeperDetails.registeredKeeperPostcode =
        registeredKeeper.address?.postCode || '';
      // Not setting postkey for now because we don't get enough data back from api
      // registeredKeeperDetails.registeredKeeperPostkey =
      //   registeredKeeper.address?.postCode && registeredKeeper.address.displayName
      //     ? { id: '', name: registeredKeeper.address?.displayName }
      //     : undefined;
      registeredKeeperDetails.registeredKeeperAddress = registeredKeeper.address
        ? registeredKeeper.address
        : undefined;
    }
  }

  const vehicleDetails: Partial<QuoteJourneyFormData> = {
    vehicleRegistration: vehicle?.properties?.vehicleRegistration || '',
    vehicleType: {
      id: assetTypeToVehicleType(vehicle?.type || AssetType.MOTOR_CAR),
      name: vehicle?.type || '',
    },
    vehicleModel: {
      id: vehicle?.properties?.vehicleAbiCode || '',
      name: vehicle?.properties?.description || '',
    },
    gearboxType: {
      id: vehicle?.properties?.vehicleTransmission as VehicleTransmissionType,
      name: vehicle?.properties?.vehicleTransmission || '',
    },
    vehicleMake: {
      id: vehicle?.properties?.vehicleMake || '',
      name: vehicle?.properties?.vehicleMake || '',
    },
    fuelType: {
      id: vehicle?.properties?.vehicleFuelType as VehicleFuelType,
      name: vehicle?.properties?.vehicleFuelType || '',
    },
    yearOfManufacture: {
      id: vehicle?.properties?.vehicleStartYear || '',
      name: vehicle?.properties?.vehicleStartYear || '',
    },
  };

  const metaDetails: Partial<QuoteJourneyFormData> = {
    journeyStartPosition: QuoteJourneyRoutes.CoverLength,
    quoteId: id,
    price: quote.price,
    product: quote.product,
    underwriter: quote.underwriterName as UnderWriterName,
  };

  const coverDetails: Partial<QuoteJourneyFormData> = {
    coverLength: {
      id: `${quote.duration?.value} ${quote.duration?.type}`,
      ...quote.duration,
    },
    coverStartQuestion: isInFuture
      ? {
          id: 'EXACT',
          name: 'An exact time and day',
        }
      : { id: 'ASAP', name: 'As soon as possible' },
    exactStartDate: isInFuture
      ? {
          day: localStartDate.get('date').toString(),
          month: (localStartDate.get('month') + 1).toString(),
          year: localStartDate.get('year').toString(),
          parsedDate: localStartDate.toISOString(),
        }
      : { day: '', month: '', year: '' },
    exactStartHour: isInFuture
      ? {
          id: localStartDate.get('hour').toString().padStart(2, '0'),
          name: localStartDate.get('hour').toString().padStart(2, '0'),
        }
      : undefined,
    exactStartMinute: isInFuture
      ? {
          id: localStartDate.get('minute').toString().padStart(2, '0'),
          name: localStartDate.get('minute').toString().padStart(2, '0'),
        }
      : undefined,
    addOns:
      quote.availableAddOns?.map(addon => ({
        id: addon.type as AddOnType,
        name: addon.name || '',
        description: addon.description || '',
        price: addon.price?.total || 0,
        checked: quote.selectedAddOns?.includes(addon.type as AddOnType) || false,
      })) || [],
  };

  return getQuoteJourneyInitialData({
    ...personDetails,
    ...registeredKeeperDetails,
    ...vehicleDetails,
    ...metaDetails,
    ...coverDetails,
  });
};
