import { useEffect } from 'react';
import { env } from '../../env';
// import PageContainer from '../Layout/PageContainer/PageContainer';

const MentionMeLanding = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${env.config.MENTION_ME_REFER_URL}/api/v2/referreroffer/mm2db2abda?situation=landingpage&locale=en_GB`;
    script.async = true;
    document.body.appendChild(script);
  });

  return (
    <div className="flex flex-col mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
      <div id="mmWrapper" />
    </div>
  );
};

export default MentionMeLanding;
