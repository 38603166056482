import { Button, Card, Dialog } from '@dayinsure/components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_PRIVACY_POLICY_URL,
  DEFAULT_TOBA_URL,
  MULSANNE_PRIVACY_POLICY_URL,
} from '../../../../constants/apps';

type MulsanneDialogProps = {
  open: boolean;
  onClose: () => void;
};

const MulsanneDialog = ({ open, onClose }: MulsanneDialogProps) => {
  const { t } = useTranslation('quotes');
  const navigateWithReferrer = useNavigate();

  const resetSearch = () => {
    navigateWithReferrer('/quote');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeOnBackdropClick={false}
      title={t('mulsanne_dialog.title')}
    >
      <Card
        classNames="!bg-popup-background text-left font-lato text-sm text-popup-content-1"
        paddingLevel="small"
      >
        <p className="mb-4">{t('mulsanne_dialog.p1')}</p>
        <p className="mb-4">
          <Trans
            i18nKey="mulsanne_dialog.p2"
            t={t}
            components={[
              <a
                href="https://www.dayinsure.com/mulsanne/"
                target="_blank"
                rel="noopener noreferrer"
                className="popup-link"
              >
                click here
              </a>,
            ]}
          />
        </p>
        <p className="mb-4 font-semibold">{t('mulsanne_dialog.p3')}</p>
        <p>
          <Trans
            i18nKey="mulsanne_dialog.p4"
            t={t}
            components={[
              <a
                href={DEFAULT_TOBA_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="popup-link"
              >
                Terms of Business
              </a>,
              <a
                href={DEFAULT_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="popup-link"
              >
                Dayinsure Privacy Policy
              </a>,
              <a
                href={MULSANNE_PRIVACY_POLICY_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="popup-link"
              >
                Mulsanne Privacy Policy
              </a>,
              <a
                href={
                  // mulsanneIPID?.detail?.documentUrl ||
                  'https://www.dayinsure.com/document/mulsanne-motor-insurance-product-information-document-ipid/'
                }
                target="_blank"
                rel="noopener noreferrer"
                className="popup-link"
              >
                Mulsanne IPID
              </a>,
            ]}
          />
        </p>
      </Card>
      <Button
        id="cta-mulsanne-dialog-continue"
        fullWidth
        onClick={onClose}
        text={t('mulsanne_dialog.cta_continue')}
        classNames="mt-6 md:mt-8"
      />
      <Button
        id="cta-mulsanne-dialog-reset"
        fullWidth
        onClick={resetSearch}
        variant="dialogSecondary"
        text={t('mulsanne_dialog.cta_reset')}
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};

export default MulsanneDialog;
