import { MarketConsentType } from '../api';
import Referrer from '../types/referrer';

import DayInsureLogo from '../assets/logos/logo_dayinsure.svg';
import AvivaLogo from '../assets/logos/logo_aviva.png';
import ForYoungDriversLogo from '../assets/logos/logo_4yd.png';
import AutonetLogo from '../assets/logos/logo_autonet.png';
import CornMarketLogo from '../assets/logos/logo_cornmarket.png';
import GeneralAccidentLogo from '../assets/logos/logo_ga.png';
import JauntLogo from '../assets/logos/logo_jaunt.png';
import QuoteMeHappyLogo from '../assets/logos/logo_quotemehappy.png';
import RACLogo from '../assets/logos/logo_rac.png';
import VauxhallLogo from '../assets/logos/logo_vauxhall.png';
import RedDrivingSchool from '../assets/logos/logo_reddrivingschool.png';
import StellantisLogo from '../assets/logos/logo_stellantis.png';
import PrincialLogo from '../assets/logos/logo_principal.png';
import ComfortLogo from '../assets/logos/logo_comfort.png';

const defaultReferrer: Referrer = {
  name: 'Dayinsure',
  theme: 'dayinsure',
  logo: DayInsureLogo,
  logoSize: 'regular',
  pageMeta: {
    title: 'Get A Quote | Car Insurance Quotes Online | Temp Cover',
    description: '',
    faviconUrl: '/favicon.ico',
  },
  customerAccessEnabled: true,
  identityProviderName: 'dayinsureWebIdSrv',
  identityProviderDisplayName: 'Dayinsure',
  defaultCancelUrl: '/quote',
  marketingConsent: {
    disclaimerText:
      "Fancy receiving discounts, rewards, interesting news or special deals? Select your preferred contact method(s) and we'll send you occasional information that we hope you'll love. We'll also include the best offers from our friendly partners and the wider insurance group we belong to.",
    options: [
      { id: MarketConsentType.EMAIL, name: 'Email' },
      { id: MarketConsentType.SMS, name: 'Text' },
      { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
      { id: MarketConsentType.POST, name: 'Post' },
    ],
  },
  homeUrl: '/quote',
  showAppLinks: false,
  footerDomain: { displayName: 'dayinsure.com', href: 'https://www.dayinsure.com/' },
  footerLegalWording:
    'Dayinsure™ is a registered trademark © {{year}} Dayinsure.com Ltd. Authorised and regulated by the Financial Conduct Authority No: 304010. Company Registration No: 04996289',
  useWebsiteDocuments: true,
  mentionMeEnabled: false,
};

const referrers: { [key: string]: Referrer } = {
  dayinsure: {
    ...defaultReferrer,
    showAppLinks: true,
    mentionMeEnabled: true,
  },
  dayinsuree: {
    ...defaultReferrer,
    name: 'Dayinsuree',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=Dayinsuree',
    homeUrl: '/quote?referrer=Dayinsuree',
    mentionMeEnabled: true,
  },
  dayinsuref: {
    ...defaultReferrer,
    name: 'Dayinsuref',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=Dayinsuref',
    homeUrl: '/quote?referrer=Dayinsuref',
    mentionMeEnabled: true,
  },
  dayinsuredts: {
    ...defaultReferrer,
    name: 'DayinsureDTS',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=DayinsureDTS',
    homeUrl: '/quote?referrer=DayinsureDTS',
    mentionMeEnabled: true,
  },
  dayinsureinf: {
    ...defaultReferrer,
    name: 'DayinsureINF',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=DayinsureINF',
    homeUrl: '/quote?referrer=DayinsureINF',
    mentionMeEnabled: true,
  },
  dayinsurecrewe: {
    ...defaultReferrer,
    name: 'DayinsureCREWE',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=DayinsureCREWE',
    homeUrl: '/quote?referrer=DayinsureCREWE',
    mentionMeEnabled: true,
  },
  rac: {
    ...defaultReferrer,
    name: 'RAC',
    theme: 'RAC',
    logo: RACLogo,
    logoSize: 'regular',
    customerAccessEnabled: true,
    identityProviderName: 'racWebIdSrv',
    identityProviderDisplayName: 'RAC Dayinsure',
    defaultCancelUrl: '/quote?referrer=RAC',
    marketingConsent: {
      disclaimerText:
        'From time to time we may contact you to keep you informed about RAC products and services.\n\nIf you are happy to be contacted by any of the following methods, please select the appropriate box(es).\n\nWe will not share your personal data with any third party for marketing purposes. For full details about how will use your data please refer to our [privacy notice].',
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    homeUrl: '/quote?referrer=RAC',
    footerDomain: {
      displayName: 'www.rac.co.uk',
      href: 'https://www.rac.co.uk',
    },
    footerLegalWording:
      'RAC Financial Services Limited act as an introducer to Dayinsure.com Ltd for RAC Day Insurance. RAC Financial Services Limited is registered in England No. 5171817. Registered Office: RAC House, Brockhurst Crescent, Walsall WS5 4AW. RAC Day Car Insurance is arranged and administered by Dayinsure.com Ltd, which is registered in England no. 04996289, registered office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Both companies are authorised and regulated by the Financial Conduct Authority. RAC Day Insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No 2116. Registered Office: Pitheavlis, Perth PH2 0NH. Aviva Insurance Limited are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.',
    useWebsiteDocuments: false,
  },
  racd: {
    ...defaultReferrer,
    name: 'RACD',
    theme: 'RAC',
    logo: RACLogo,
    logoSize: 'regular',
    customerAccessEnabled: true,
    identityProviderName: 'racWebIdSrv',
    identityProviderDisplayName: 'RAC Dayinsure',
    defaultCancelUrl: '/quote?referrer=RACD',
    marketingConsent: {
      disclaimerText:
        'From time to time we may contact you to keep you informed about RAC products and services.\n\nIf you are happy to be contacted by any of the following methods, please select the appropriate box(es).\n\nWe will not share your personal data with any third party for marketing purposes. For full details about how will use your data please refer to our [privacy notice].',
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    homeUrl: '/quote?referrer=RACD',
    footerDomain: {
      displayName: 'www.rac.co.uk',
      href: 'https://www.rac.co.uk',
    },
    footerLegalWording:
      'RAC Financial Services Limited act as an introducer to Dayinsure.com Ltd for RAC Day Insurance. RAC Financial Services Limited is registered in England No. 5171817. Registered Office: RAC House, Brockhurst Crescent, Walsall WS5 4AW. RAC Day Car Insurance is arranged and administered by Dayinsure.com Ltd, which is registered in England no. 04996289, registered office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Both companies are authorised and regulated by the Financial Conduct Authority. RAC Day Insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No 2116. Registered Office: Pitheavlis, Perth PH2 0NH. Aviva Insurance Limited are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.',
    useWebsiteDocuments: false,
  },
  racdl: {
    ...defaultReferrer,
    name: 'RACDL',
    theme: 'RAC',
    logo: RACLogo,
    logoSize: 'regular',
    customerAccessEnabled: true,
    identityProviderName: 'racWebIdSrv',
    identityProviderDisplayName: 'RAC Dayinsure',
    defaultCancelUrl: '/quote?referrer=RACDL',
    marketingConsent: {
      disclaimerText:
        'From time to time we may contact you to keep you informed about RAC products and services.\n\nIf you are happy to be contacted by any of the following methods, please select the appropriate box(es).\n\nWe will not share your personal data with any third party for marketing purposes. For full details about how will use your data please refer to our [privacy notice].',
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    homeUrl: '/quote?referrer=RACDL',
    footerDomain: {
      displayName: 'www.rac.co.uk',
      href: 'https://www.rac.co.uk',
    },
    footerLegalWording:
      'RAC Financial Services Limited act as an introducer to Dayinsure.com Ltd for RAC Day Insurance. RAC Financial Services Limited is registered in England No. 5171817. Registered Office: RAC House, Brockhurst Crescent, Walsall WS5 4AW. RAC Day Car Insurance is arranged and administered by Dayinsure.com Ltd, which is registered in England no. 04996289, registered office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Both companies are authorised and regulated by the Financial Conduct Authority. RAC Day Insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No 2116. Registered Office: Pitheavlis, Perth PH2 0NH. Aviva Insurance Limited are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.',
    useWebsiteDocuments: false,
  },
  hyperjar: {
    ...defaultReferrer,
    name: 'Hyperjar',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=Hyperjar',
    homeUrl: '/quote?referrer=Hyperjar',
  },
  aviva: {
    ...defaultReferrer,
    name: 'Aviva',
    theme: 'Aviva',
    logo: AvivaLogo,
    logoSize: 'regular',
    customerAccessEnabled: true,
    identityProviderName: 'avivaWebIdSrv',
    identityProviderDisplayName: 'Aviva Dayinsure',
    defaultCancelUrl: '/quote?referrer=Aviva',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=Aviva',
    footerDomain: {
      displayName: 'aviva.co.uk',
      href: 'https://www.aviva.co.uk/insurance/motor/short-term-car-insurance/',
    },
    footerLegalWording:
      'Short Term Car Insurance is introduced by Aviva UK Digital Limited (Registered in England No. 09766150. Registered Office: 8 Surrey Street, Norwich, Norfolk, NR1 3NG to Dayinsure.com limited (Registered in England Authority No: 304010, Company Registered No: 04996289, Registered Office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY) Both companies are authorised and regulated by the Financial Conduct Authority.',
    useWebsiteDocuments: false,
  },
  jaunt: {
    ...defaultReferrer,
    name: 'Jaunt',
    theme: 'Jaunt',
    logo: JauntLogo,
    logoSize: 'large',
    pageMeta: {
      ...defaultReferrer.pageMeta,
      faviconUrl: '/referrerFavicons/jaunt-favicon.ico',
    },
    customerAccessEnabled: true,
    identityProviderName: 'jauntWebIdSrv',
    identityProviderDisplayName: 'Jaunt Dayinsure',
    defaultCancelUrl: '/quote?referrer=Jaunt',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
      ],
    },
    homeUrl: '/quote?referrer=Jaunt',
    footerDomain: {
      displayName: 'jaunt.co.uk',
      href: 'https://www.jaunt.co.uk/',
    },
    footerLegalWording:
      'Jaunt is a trading name of BISL Limited. Registered Office: Pegasus House, Bakewell Road, Orton Southgate, Peterborough, PE2 6YS. Registered in England No. 3231094. Jaunt car insurance is arranged and administered by Dayinsure.com Ltd. Registered Office Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Registered in England No 04996289. Both companies are authorised and regulated by the Financial Conduct Authority',
    useWebsiteDocuments: false,
  },
  comparethemarket: {
    ...defaultReferrer,
    name: 'CompareTheMarket',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=CompareTheMarket',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=CompareTheMarket',
  },
  moneysupermarket: {
    ...defaultReferrer,
    name: 'MoneySuperMarket',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=MoneySupermarket',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=MoneySupermarket',
  },
  gocompare: {
    ...defaultReferrer,
    name: 'GoCompare',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=GoCompare',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=GoCompare',
  },
  quotezone: {
    ...defaultReferrer,
    name: 'QuoteZone',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=QuoteZone',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=QuoteZone',
  },
  quotemehappy: {
    ...defaultReferrer,
    name: 'QuoteMeHappy',
    theme: 'QuoteMeHappy',
    logo: QuoteMeHappyLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    identityProviderName: 'quoteMeHappyWebIdSrv',
    identityProviderDisplayName: 'Quote Me Happy',
    defaultCancelUrl: '/quote?referrer=QuoteMeHappy',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=QuoteMeHappy',
    footerDomain: {
      displayName: 'quotemehappy.com',
      href: 'https://www.quotemehappy.com/',
    },
    footerLegalWording:
      'Quotemehappy.com is a trading name of Aviva UK Digital Limited who acts as an introducer to Dayinsure.com Limited for Quotemehappy.com short term car insurance. Aviva UK Digital Limited is registered in England No. 09766150, Registered Office : Registered Office: 8 Surrey Street, Norwich, Norfolk, NR1 3NG. Quotemehappy.com short term car insurance is arranged and administered by Dayinsure.com Limited, which is registered in England No. 04996289, Registered Office : Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire, CW6 9UY. Both companies are authorised and regulated by the Financial Conduct Authority. Quotemehappy.com short term car insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No. 2116, Registered Office : Pitheavlis, Perth PH2 0NH. Aviva Insurance Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.',
    useWebsiteDocuments: false,
  },
  money: {
    ...defaultReferrer,
    name: 'Money',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=Money',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=Money',
  },
  moneymaximiser: {
    ...defaultReferrer,
    name: 'MoneyMaximiser',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=MoneyMaximiser',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=Money',
  },
  autonet: {
    ...defaultReferrer,
    name: 'Autonet',
    theme: 'Autonet',
    logo: AutonetLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    identityProviderName: 'autonetWebIdSrv',
    identityProviderDisplayName: 'Autonet',
    defaultCancelUrl: '/quote?referrer=Autonet',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=Autonet',
    footerDomain: {
      displayName: 'autonetinsurance.co.uk',
      href: 'https://www.autonetinsurance.co.uk/',
    },
    useWebsiteDocuments: false,
  },
  cornmarket: {
    ...defaultReferrer,
    name: 'Cornmarket',
    theme: 'Cornmarket',
    logo: CornMarketLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    identityProviderName: 'cornmarketWebIdSrv',
    identityProviderDisplayName: 'Cornmarket',
    defaultCancelUrl: '/quote?referrer=Cornmarket',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=Cornmarket',
    useWebsiteDocuments: false,
  },
  vauxhall: {
    ...defaultReferrer,
    name: 'Vauxhall',
    theme: 'Vauxhall',
    logo: VauxhallLogo,
    logoSize: 'large',
    identityProviderName: 'vauxhallWebIdSrv',
    identityProviderDisplayName: 'Vauxhall Dayinsure',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=Vauxhall',
    marketingConsent: {
      disclaimerText: '',
      options: [],
    },
    homeUrl: '/quote?referrer=Vauxhall',
    footerDomain: { displayName: 'vauxhall.co.uk', href: 'https://www.vauxhall.co.uk/' },
    useWebsiteDocuments: false,
  },
  foryoungdrivers: {
    ...defaultReferrer,
    name: '4YoungDrivers',
    theme: 'ForYoungDrivers',
    logo: ForYoungDriversLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=4YoungDrivers',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=4YoungDrivers',
    useWebsiteDocuments: false,
  },
  generalaccident: {
    ...defaultReferrer,
    name: 'GeneralAccident',
    theme: 'GeneralAccident',
    logo: GeneralAccidentLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    identityProviderName: 'generalAccidentWebIdSrv',
    identityProviderDisplayName: 'General Accident',
    defaultCancelUrl: '/quote?referrer=GeneralAccident',
    marketingConsent: {
      disclaimerText: defaultReferrer.marketingConsent.disclaimerText,
      options: [{ id: MarketConsentType.EMAIL, name: 'Email' }],
    },
    homeUrl: '/quote?referrer=GeneralAccident',
    footerDomain: {
      displayName: 'generalaccident.com',
      href: 'https://www.generalaccident.com/',
    },
    useWebsiteDocuments: false,
    footerLegalWording:
      'General Accident is a trading name of Aviva UK Digital Limited who acts as an introducer to Dayinsure.com Limited for General Accident short term car insurance. Aviva UK Digital Limited is registered in England No. 09766150, Registered Office: 8 Surrey Street, Norwich, Norfolk, NR1 3NG. General Accident short term car insurance is arranged and administered by Dayinsure.com Limited, which is registered in England No. 04996289, Registered Office : Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire, CW6 9UY. Both companies are authorised and regulated by the Financial Conduct Authority. General Accident short term car insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No. 2116, Registered Office : Pitheavlis, Perth PH2 0NH. Aviva Insurance Limited is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.',
  },
  oneanswer: {
    ...defaultReferrer,
    name: 'OneAnswer',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=Oneanswer',
    homeUrl: '/quote?referrer=Oneanswer',
    useWebsiteDocuments: false,
  },
  topcashback: {
    ...defaultReferrer,
    name: 'TopCashback',
    defaultCancelUrl: '/quote?referrer=TopCashback',
    homeUrl: '/quote?referrer=TopCashback',
    mentionMeEnabled: true,
  },
  quidco: {
    ...defaultReferrer,
    name: 'Quidco',
    defaultCancelUrl: '/quote?referrer=quidco',
    homeUrl: '/quote?referrer=quidco',
    mentionMeEnabled: true,
  },
  reddrivingschool: {
    ...defaultReferrer,
    name: 'RedDrivingSchool',
    theme: 'RedDrivingSchool',
    logo: RedDrivingSchool,
    logoSize: 'large',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=RedDrivingSchool',
    marketingConsent: {
      disclaimerText:
        'From time to time we may contact you to keep you informed about RDS Driving Services and products.\n\nIf you are happy to be contacted by any of the following methods, please select the appropriate box(es).\n\nWe will not share your personal data with any third party for marketing purposes. For full details about how we will use your data please refer to our [privacy policy].',
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    homeUrl: '/quote?referrer=RedDrivingSchool',
    footerDomain: {
      displayName: 'reddrivingschool.com',
      href: 'https://www.reddrivingschool.com/',
    },
    footerLegalWording:
      'Dayinsure™ is a registered trademark © {{year}} Dayinsure.com Ltd. Authorised and regulated by the Financial Conduct Authority No: 304010. Company Registration No: 04996289. RDS Driving Services, a company registered in the UK under number 073272476. Registered Address Pavilion 6, Coxwold Way, Belasis Technology Park, Billingham, TS23 4EA',
    useWebsiteDocuments: false,
  },
  stellantis: {
    ...defaultReferrer,
    name: 'Stellantis',
    theme: 'Stellantis',
    logo: StellantisLogo,
    logoSize: 'large',
    identityProviderName: 'vauxhallWebIdSrv',
    identityProviderDisplayName: 'Vauxhall Dayinsure',
    customerAccessEnabled: true,
    defaultCancelUrl: '/quote?referrer=Stellantis',
    marketingConsent: {
      disclaimerText: '',
      options: [],
    },
    homeUrl: '/quote?referrer=Stellantis',
    footerDomain: { displayName: 'vauxhall.co.uk', href: 'https://www.vauxhall.co.uk/' },
    useWebsiteDocuments: false,
  },
  europa: {
    ...defaultReferrer,
    name: 'Europa',
    defaultCancelUrl: '/quote?referrer=Europa',
    homeUrl: '/quote?referrer=Europa',
  },
  principal: {
    ...defaultReferrer,
    name: 'Principal',
    theme: 'Principal',
    logo: PrincialLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    defaultCancelUrl: '/quote?referrer=Principal',
    homeUrl: '/quote?referrer=Principal',
    marketingConsent: {
      disclaimerText:
        "Fancy receiving discounts, rewards, interesting news or special deals? Select your preferred contact method(s) and we'll send you occasional information that we hope you'll love. We'll also include the best offers from our friendly partners and the wider insurance group we belong to.",
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    footerDomain: {
      displayName: 'principal.co.uk',
      href: 'https://www.principalinsurance.co.uk/',
    },
    footerLegalWording:
      'Principal Insurance Limited, company number 07623634 registered in England and Wales. Registered address: Principal Insurance Limited, Citygate 2, Cross Street, Sale, Manchester, M33 7JR. Principal Insurance Limited is authorised and regulated by the Financial Conduct Authority (FCA) under firm reference number 551900. Principal Temporary Car Insurance is arranged and administered by Dayinsure.com Ltd, which is registered in England no. 04996289, Registered Office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Dayinsure.com Ltd is authorised and regulated by the Financial Conduct Authority No. 304010. Principal Insurance Short Term motor insurance is underwritten by Aviva Insurance Limited. Registered in Scotland, No 2116. Registered office: Pitheavlis, Perth, PH2 0NH. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority.',
  },
  comfort: {
    ...defaultReferrer,
    name: 'Comfort',
    theme: 'Comfort',
    logo: ComfortLogo,
    logoSize: 'large',
    customerAccessEnabled: false,
    identityProviderName: 'comfortWebIdSrv',
    defaultCancelUrl: '/quote?referrer=Comfort',
    homeUrl: '/quote?referrer=Comfort',
    marketingConsent: {
      disclaimerText:
        "Fancy receiving discounts, rewards, interesting news or special deals? Select your preferred contact method(s) and we'll send you occasional information that we hope you'll love. We'll also include the best offers from our friendly partners and the wider insurance group we belong to.",
      options: [
        { id: MarketConsentType.EMAIL, name: 'Email' },
        { id: MarketConsentType.SMS, name: 'Text' },
        { id: MarketConsentType.TELEPHONE, name: 'Telephone' },
        { id: MarketConsentType.POST, name: 'Post' },
      ],
    },
    footerDomain: {
      displayName: 'comfort-insurance.co.uk',
      href: 'https://www.comfort-insurance.co.uk/',
    },
    footerLegalWording:
      'Copyright © {{year}}. Comfort Insurance © has been a registered trademark since 2019. Victor Millwell Insurance Agency Limited trading as Comfort Insurance. Victor Millwell is authorised and regulated by the Financial Conduct Authority (FCA), firms registration number 300281. Registered in England, ref. 01419341. Comfort Temporary Insurance is arranged and administered by Dayinsure.com Ltd, which is registered in England no. 04996289, Registered Office: Mara House, Tarporley Business Centre, Nantwich Road, Tarporley, Cheshire CW6 9UY. Dayinsure.com Ltd is authorised and regulated by the Financial Conduct Authority No. 304010.',
  },
};

export default referrers;
