import { Button, Card, LinearProgressBar, LoadingSpinner } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import { ApiError, GetPolicyResponseIAPIResult, PolicyState } from '../../../api';
import trophy from '../../../assets/trophy.png';
import { fullCountdown } from '../../../helpers/timeUtils/timeCountdown';
import useNavigateWithReferrer from '../../../hooks/useNavigateWithReferrer';
import useInformMentionMeOfPurchase from '../../../hooks/useInformMentionMeOfPurchase';
import PolicyDocumentsList from '../../Account/PolicyInfo/PolicyDocumentsList';

type PurchaseCompleteComponentProps = {
  policy: GetPolicyResponseIAPIResult | undefined;
  policyLoading: boolean;
  policyError: ApiError | null;
  policyId: string | undefined;
};

const PurchaseCompleteComponent = ({
  policy,
  policyError,
  policyLoading,
  policyId,
}: PurchaseCompleteComponentProps) => {
  const { t } = useTranslation('quotes');
  const navigate = useNavigateWithReferrer();

  useInformMentionMeOfPurchase(policy?.detail);

  const registration =
    policy?.detail?.policy?.policyAssets?.[0].properties?.vehicleRegistration;

  const vehicleDescription =
    policy?.detail?.policy?.policyAssets?.[0].properties?.description;

  const policyState = policy?.detail?.policy?.state;
  const progressPercentage = policy?.detail?.policy?.progressPercentage;
  const startDate = policy?.detail?.policy?.startDate;
  const endDate = policy?.detail?.policy?.endDate;
  const policyProduct = policy?.detail?.policy?.product;
  const viewPolicyLink = `/policy/${policy?.detail?.policy?.id}/info`;

  return (
    <div>
      <img src={trophy} alt="trophy" className="object-cover mx-auto mb-5 h-[218px]" />
      <h2 id="purchase-success-msg" className="mb-8 text-lg text-center">
        {t('purchase_complete.thank_you')}
      </h2>
      <Card
        paddingLevel="small"
        classNames="bg-content-background-prominent w-full mb-8 !pb-8 !pt-6 !px-6 md:!px-8 md:!pb-8 md:!pt-6 lg:!p-8"
      >
        {policyLoading && <LoadingSpinner centered />}
        {!policyLoading && !policyError && (
          <div className="flex flex-col items-start">
            <span
              className="inline-flex py-1 px-2 mb-2 font-lato text-sm leading-[120%] rounded bg-main-content-5"
              id="previous-policies-registration-plate"
            >
              {registration}
            </span>
            <span
              id="previous-dashboard-policies-car-details"
              className="flex mb-4 text-md leading-[120%] whitespace-normal lg:mb-6"
            >
              {vehicleDescription}
            </span>
            {progressPercentage !== undefined && (
              <LinearProgressBar
                id="policy-info-progress-bar"
                progressPercentage={progressPercentage}
              />
            )}
            <span className="flex mt-2 text-sm leading-[120%] text-main-content-2">
              {policyState === PolicyState.ACTIVE &&
                endDate &&
                t('purchase_complete.ends_in', {
                  countdown: fullCountdown(endDate),
                })}
              {policyState === PolicyState.UPCOMING &&
                startDate &&
                t('purchase_complete.starts_in', {
                  countdown: fullCountdown(startDate),
                })}
            </span>

            <Button
              classNames="mt-6"
              variant="secondary"
              id="purchase-view-new-policy-info"
              text={t('purchase_complete.view_policy')}
              onClick={() =>
                navigate({
                  pathname: viewPolicyLink,
                })
              }
            />
          </div>
        )}
      </Card>
      {policyProduct && (
        <PolicyDocumentsList policyId={policyId} product={policyProduct} />
      )}
    </div>
  );
};

export default PurchaseCompleteComponent;
