import { useEffect } from 'react';
import useReferrer from './useReferrer';
import { env } from '../env';

const useInformMentionMeOfPurchase = (policyDetail: any | undefined) => {
  const referrer = useReferrer();
  useEffect(() => {
    const policyHolder = policyDetail.policy.policyPersons.find(
      (person: any) => person.policyPersonType === 'PolicyHolder'
    );
    const firstName = encodeURIComponent(policyHolder?.firstName || '');
    const lastName = encodeURIComponent(policyHolder?.lastName || '');
    const email = encodeURIComponent(policyHolder?.email || '');

    const { policyReference: policyId } = policyDetail.policy;
    const { items } = policyDetail.policy.price;

    const policyPremium = items.find(
      (item: any) => item.type === 'InsurancePremium'
    ).value;
    const adminFee = items.find((item: any) => item.type === 'AdminFee').value;
    const policyPriceExcludingIpt = (
      policyPremium * (1 - policyDetail.policy.iptRate) +
      adminFee
    ).toFixed(2);

    const locale = 'en_GB';
    const currency = 'GBP';
    const situation = 'postpurchase';
    const partnerCode = 'mm2db2abda';

    const url = `${env.config.MENTION_ME_REFER_URL}/api/v2/referreroffer/${partnerCode}?firstname=${firstName}&surname=${lastName}&email=${email}&booking_id=${policyId}&booking_subtotal=${policyPriceExcludingIpt}&booking_currency=${currency}&situation=${situation}&locale=${locale}&coupon_code=EMPTY&booking_discount_amount=0&booking_item_count=1&booking_is_subscription=false&booking_is_gift=false&customer_id=${email}"`;

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  }, [referrer.mentionMeEnabled, policyDetail]);
};

export default useInformMentionMeOfPurchase;
